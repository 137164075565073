import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
    HostListener,
} from '@angular/core';
import { Contract } from 'src/utility/Storage/contract';
import { ActivatedRoute, Router } from '@angular/router';
import { EncryptedCookieStorageService } from 'src/utility/services/marketplace/encrypted-cookie-storage.service';
import { VerifyCookiesService } from 'src/utility/services/marketplace/verify-cookies.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit, AfterViewInit {
    isAccOpen1 = false;
    isAccOpen2 = false;
    isAccOpen3 = false;
    isAccOpen4 = false;
    isAccOpen5 = false;
    isAccOpen6 = false;
    countrySelect: any;
    selectedItem = '';
    selectedItemOfModal = '';

    isOpen = false;
    @Input() isModal: boolean = false;
    @Input() products: any = [];
    termsProducts: any = [
        { name: 'Club Forza', id: 'membresia', show: false },
        { name: 'Paquete Básico', id: 'basico', show: false },
        { name: 'Paquete Plus', id: 'plus', show: false },
        { name: 'Paquete Gold', id: 'gold', show: false },
        { name: 'Plan Amigo', id: 'amigo', show: false },
        { name: 'Paquete Petit', id: 'petit', show: false },
        { name: 'Paquete Platino', id: 'platino', show: false },
        { name: 'Paquete PRO', id: 'pro', show: false },
        { name: 'Paquete MICRO', id: 'micro', show: false },
        { name: 'Paquete FLEXI', id: 'flexi', show: false },
    ];

    constructor(
        private activaRoute: ActivatedRoute,
        private router: Router,
        private encryptedCookieStorageService: EncryptedCookieStorageService
    ) {}

    ngAfterViewInit(): void {
        if (!this.isModal) {
        }
    }

    ngOnInit() {
        this.countrySelect = environment.multiCountryData[0].countrySelect;
        if (environment.rol == 'Ventas telemercadeo') {
            this.countrySelect =
                environment.TelemercadeoCountryData[0].countrySelect;
        }
        this.selectedItem = this.activaRoute.snapshot.paramMap.get('id');

        if (this.selectedItem != '' && this.selectedItem != null) {
            setTimeout(() => {
                document
                    .getElementById(this.selectedItem)
                    .scrollIntoView({ block: 'start' });
                window.scrollBy(0, -55);
                // document.getElementById('container').scrollTop -= 55;
            }, 0);
        }
    }

    ngOnChanges(changes: any) {
        if (this.products?.length > 0) {
            for (let i = 0; i < this.termsProducts.length; i++) {
                this.termsProducts[i].show = false;
            }
            setTimeout(() => {
                for (let i = 0; i < this.products.length; i++) {
                    const index = this.termsProducts.findIndex(
                        (item) =>
                            item.name.toLowerCase() ==
                            this.products[i].CatProductName.toLowerCase()
                    );
                    if (index >= 0) {
                        this.termsProducts[index].show = true;
                        if (i == this.products.length - 1) {
                            for (
                                let j = 0;
                                j < this.termsProducts.length;
                                j++
                            ) {
                                if (this.termsProducts[j].show) {
                                    this.selectedItem =
                                        this.termsProducts[j].id;
                                    break;
                                }
                            }
                        }
                    }
                }
            }, 100);
        }
    }

    openAccordion(event, item): void {
        if (event) {
            this.selectedItem = item;
        } else {
            this.selectedItem = '';
        }
    }
}
